import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Components
import SiteLinks from './components/site-links'
import Hero from './components/story-hero'
import TeamQuotes from './components/team'
import PartnerWithUs from './components/partner-with-us'
import OurStory from './components/our-story'
import Merch from './components/merch'
import Instagram from './components/instagram'

// function About({ data: { about, instagram, people } }) {
function Story() {
  return (
    <Layout>
      <SiteNav fullLogo />
      <SiteLinks activePage="story" />
      <Hero />
      <TeamQuotes />
      <PartnerWithUs />
      <OurStory />
      <Merch />
      <Instagram />
    </Layout>
  )
}

export default Story
